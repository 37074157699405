// ---------------------------------------------------------
// font variables should start with --font
// ---------------------------------------------------

:root {
  --font-fallback: 'Verdana', sans-serif;
  --font-family-base: 'Untitled', serif;
  --font-family-base-bold: 'Untitled Bold', serif;
  --font-family-base-italic: 'Untitled Italic', serif;
  --font-family-headings: 'Mark Pro Medium', var(--font-fallback);
  --font-family-headings-regular: 'Mark Pro', var(--font-fallback);
  --font-family-headings-italic: 'Mark Pro Italic', var(--font-fallback);
  --font-family-headings-bold: 'Mark Pro Bold', var(--font-fallback);
  --font-size-base: 18px;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}
