// --------------------------------------------------------

@mixin hover {
  @media (hover: hover) {
    &:hover,
    &:focus {
      @content;
    }
  }
}
