@use 'global' as *;
// ---------------------------------------------------------

@forward 'global/variables';

// --------------------------------------------------------

html {
  height: 100%;
}

body {
  @include font-smoothing;

  color: var(--global-off-black);
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: 1;
  margin: 0;
  margin-top: var(--global-header-height);
  overflow-x: hidden;

  * {
    scroll-margin: 80px;
  }
}

// --------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: text;
  margin: 0 0 20px;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;

  margin-bottom: 0.4rem;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

body,
h4 {
  em,
  i {
    font-family: var(--font-family-base-italic);
    font-style: normal;
  }

  strong,
  b {
    font-family: var(--font-family-base-bold);
  }
}

h1,
h2,
h3,
h5,
h6 {
  em,
  i {
    font-family: var(--font-family-headings-italic);
    font-style: italic;
  }

  strong,
  b {
    font-family: var(--font-family-headings-bold);
    font-style: normal;
  }
}

// --------------------------------------------------------

a {
  text-decoration: none;
}

p a {
  @include link;

  color: var(--color-lagoon);
}

p {
  @include body;

  margin-bottom: 20px;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

::selection {
  background-color: var(--color-limoncello);
  color: var(--color-off-black);
}

li {
  font-family: var(--font-family-base);
  margin-bottom: 0.6rem;
}

// ---------------------------------------------------------

button {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
}

// --------------------------------------------------------

img {
  max-width: 100%;
}

// ---------------------------------------------------------

hr {
  border: 1px solid var(--color-prime-gray);
  margin-bottom: 1.5rem;
}

// ---------------------------------------------------------

@mixin light-text {
  color: var(--color-white);

  p a {
    color: var(--color-ice);

    @include hover {
      color: var(--color-prime-teal);
    }
  }
}

.background {
  &_white {
    background-color: var(--color-white);
  }

  &_cement {
    background-color: var(--color-cement);
  }

  &_anvil {
    background-color: var(--color-anvil);

    @include light-text;
  }

  &_dusk {
    background-color: var(--color-dusk);

    @include light-text;
  }

  &_ice {
    background-color: var(--color-ice);
  }

  &_ice-light {
    background-color: var(--color-ice-light);
  }

  &_lagoon {
    background-color: var(--color-lagoon);

    @include light-text;
  }

  &_amp-yellow {
    background-color: var(--color-amp-yellow);
  }

  &_limoncello {
    background-color: var(--color-limoncello);

    ::selection {
      background-color: var(--color-moss);
      color: var(--color-off-black);
    }
  }

  &_off-black {
    background-color: var(--color-off-black);

    @include light-text;
  }

  &_prime-teal {
    background-color: var(--color-prime-teal);
  }

  &_light-purple {
    background-color: var(--color-light-purple);
  }

  &_ice-gradient {
    background: linear-gradient(
      0deg,
      hsl(180deg 79% 82%) 0%,
      hsl(180deg 78% 86%) 20%,
      hsl(180deg 77% 90%) 40%,
      hsl(180deg 77% 93%) 60%,
      hsl(180deg 76% 97%) 80%,
      hsl(0deg 0% 100%) 100%
    );
  }

  &_ice-gradient-reversed {
    background: linear-gradient(
      180deg,
      hsl(180deg 79% 82%) 0%,
      hsl(180deg 78% 86%) 20%,
      hsl(180deg 77% 90%) 40%,
      hsl(180deg 77% 93%) 60%,
      hsl(180deg 76% 97%) 80%,
      hsl(0deg 0% 100%) 100%
    );
  }

  &_midnight-gradient {
    background: linear-gradient(
      180deg,
      hsl(180deg 79% 82%) 0%,
      hsl(187deg 49% 53%) 20%,
      hsl(187deg 100% 30%) 40%,
      hsl(187deg 100% 21%) 60%,
      hsl(189deg 78% 9%) 80%,
      hsl(0deg 0% 5%) 100%
    );

    @include light-text;
  }

  &_midnight-gradient-reversed {
    background: linear-gradient(
      0deg,
      hsl(180deg 79% 82%) 0%,
      hsl(187deg 49% 53%) 20%,
      hsl(187deg 100% 30%) 40%,
      hsl(187deg 100% 21%) 60%,
      hsl(189deg 78% 9%) 80%,
      hsl(0deg 0% 5%) 100%
    );

    @include light-text;
  }

  &_dusk-gradient {
    background: linear-gradient(
      180deg,
      hsl(0deg 0% 5%) 0%,
      hsl(190deg 52% 14%) 20%,
      hsl(188deg 100% 17%) 41%,
      hsl(188deg 100% 17%) 60%,
      hsl(190deg 52% 14%) 80%,
      hsl(0deg 0% 5%) 100%
    );

    @include light-text;
  }

  &_deepest-pink-gradient {
    background: linear-gradient(
      0deg,
      hsl(0deg 0% 5%) 0%,
      hsl(317deg 59% 18%) 25%,
      hsl(316deg 73% 22%) 50%,
      hsl(317deg 59% 18%) 75%,
      hsl(0deg 0% 5%) 100%
    );

    @include light-text;
  }

  &_dusk-to-black-gradient {
    background-image: linear-gradient(
      0deg,
      hsl(0deg 0% 5%) 0%,
      hsl(192deg 22% 9%) 20%,
      hsl(191deg 38% 12%) 40%,
      hsl(190deg 52% 14%) 60%,
      hsl(189deg 70% 16%) 80%,
      hsl(188deg 100% 17%) 100%
    );

    @include light-text;
  }

  &_black-to-dusk-gradient {
    background-image: linear-gradient(
      180deg,
      hsl(0deg 0% 5%) 0%,
      hsl(192deg 22% 9%) 20%,
      hsl(191deg 38% 12%) 40%,
      hsl(190deg 52% 14%) 60%,
      hsl(189deg 70% 16%) 80%,
      hsl(188deg 100% 17%) 100%
    );

    @include light-text;
  }
}

.container {
  @include container;
}

// ---------------------------------------------- Marketo Container and Header Styles

.mkto_container {
  overflow: hidden !important;
  position: relative;
  width: 100% !important;

  h3 {
    @include h5;
  }

  @include media($small) {
    padding: 10px 8%;
  }
}

// ---------------------------------------------- Marketo Mobile Screen Sizes
// Disable Stylelint for Marketo Styles
// ---------------------------------------------------------
// stylelint-disable selector-class-pattern, selector-id-pattern
// stylelint-disable selector-pseudo-class-no-unknown, no-descending-specificity

@include media($x-small-highest, down) {
  .mktoForm .mktoFormRow .mktoField,
  .mktoRequiredField {
    font-weight: 0 !important;
  }

  .mktoForm .mktoFormRow .mktoRequiredField .mktoAsterix {
    padding-right: 2px !important;
  }

  .mktoFormRow .mktoFormCol {
    min-height: 48px !important;
  }

  .mktoFieldDescriptor {
    margin-bottom: 0 !important;
    position: relative !important;
  }

  .mktoFormRow {
    margin: 0 !important;
    min-height: 0 !important;
    padding: 0 !important;
  }
}

// ---------------------------------------------- Marketo FullScreen Styling

.mktoError {
  font-size: 15px !important;
  position: relative;
  right: 5px !important;
  top: 25px !important;
  width: fit-content !important;
  z-index: var(--z-index-plus-three) !important;

  .mktoArrowWrap {
    top: -7px !important;
  }

  .mktoErrorArrow {
    display: none !important;
  }

  .mktoErrorMsg {
    background-color: var(--color-cloud) !important;
    background-image: none !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    color: var(--color-anvil) !important;
    min-height: 26px;
    min-width: 150px;
    text-shadow: none !important;
  }
}

.mktoForm {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

// -------------------------------------------------------------------------------------------------------------------

.mktoFieldWrap {
  font-family: var(--font-family-base) !important;
  font-size: 20px;
  padding-bottom: 0.5rem !important;
  width: 100%;

  .mktoField {
    padding-bottom: 8px !important;
  }

  .mktoLabel {
    color: var(--color-slate) !important;
    font-family: var(--font-family-headings-bold) !important;
    font-size: 0.75rem;
    letter-spacing: 1.5px !important;
    margin-top: 2px;
    text-transform: uppercase;
    width: fit-content !important;
  }

  & > input,
  & > textarea,
  & > select {
    border: 1px solid var(--color-prime-gray);
    border-radius: 5px;
    color: var(--color-off-black);
    margin-top: 6px !important;
    padding: 10px !important;
    transition: border-color 100ms ease;
    width: 100% !important;

    &::placeholder {
      color: transparent;
    }

    &:active,
    &:focus {
      border: 1px solid var(--color-ocean);
    }

    @include media($small, down) {
      min-height: 42px;
    }
  }

  & > select {
    appearance: none;
    background: url('/icons/chevron-down.svg') no-repeat right;
    background-position: 97%;
    background-size: 10px;
    cursor: pointer !important;
  }
}

.mktoButtonRow {
  margin: 1rem 0 !important;

  .mktoButton {
    background: var(--color-amp-yellow) !important;
    border: 1px solid var(--color-off-black) !important;
    border-radius: var(--global-button-border-radius);
    color: var(--color-off-black) !important;
    font-family: var(--font-family-headings-bold);
    font-size: 16px !important;
    line-height: 26px;
    padding: 5px 20px 8px !important;
    transition: background-color 250ms ease, color 250ms ease;
    width: 100% !important;

    &:hover {
      background: var(--color-moss) !important;
    }
  }
}

.mktoCheckboxList,
.mktoRadioList {
  margin-top: 5px !important;
  padding: 0 !important;
  width: 100% !important;

  input {
    margin-top: 5px !important;

    @include media($small-highest, down) {
      margin-top: 0 !important;
    }
  }

  label {
    font-size: 17px;
  }
}

// ----------------------------------------------- Reset Marketo Styles

.mktoButtonWrap {
  margin-left: 0 !important;
}

.mktoLayoutLeft {
  margin: 0 !important;
  width: 100%;
}

.mktoGutter {
  width: 0 !important;
}

.mktoFieldDescriptor {
  margin: 0 !important;
  width: 100%;
}

.mktoFormCol {
  width: 100% !important;
}

.mktoOffset {
  width: 0 !important;
}

.mktoHtmlText {
  font-size: 1rem !important;
  width: 100% !important;
}

.mktoAsterix {
  color: var(--color-saturated-red-a11y) !important;
  font-size: 0.9rem;
  transform: translateY(1px);
}

input:focus,
input[type='text']:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

// Override iOS 5+ rounded inputs

input {
  border-radius: 0;
}

input:hover,
select:hover,
textarea:hover {
  border: 1px solid var(--color-prime-teal);
}

input[type='search'] {
  appearance: none;
}

@include chrome-input-override {
  background: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}

textarea#notesComments {
  margin-top: 0.5rem;
  min-height: 120px;
  width: 100% !important;
}

// ----------------------------------------------- Swiftype styling

// Input field

.st-default-search-input,
.st-ui-search-input {
  border: none !important;
  border-radius: 5px 0 0 5px !important;
  font-family: var(--font-family-base) !important;
  font-size: 16px !important;
  padding-left: 1rem !important;
  width: 100% !important;
}

// Modal

.st-ui-container,
.st-ui-container-overlay,
.st-ui-injected-overlay-container {
  border-radius: var(--global-border-radius) !important;
}

.st-default-autocomplete {
  @include media($full-navigation, down) {
    position: fixed !important;
    top: 125px;
  }
}

.st-ui-content,
.st-search-results,
.st-ui-type-heading,
.st-ui-type-detail,
.st-ui-type-detail-bold {
  font-family: var(--font-family-base) !important;
}

.st-ui-injected-overlay-container .st-ui-header input[type='text']:focus {
  border-bottom: 1px solid var(--color-moss) !important;
}

// Hide modal search icon in input

span.st-ui-search-icon {
  background-image: none !important;
}

// Modal popup

.st-ui-pagination-link,
.st-ui-pagination-number-link,
.st-result-pagination-link {
  color: var(--color-lagoon) !important;

  @include link;

  @include hover {
    color: var(--color-prime-teal) !important;
  }
}

// Modal suggested results

.st-ui-slide-autocomplete,
.st-autocomplete-keyboard-navigable,
.st-autocomplete-transient-on-select-and-results {
  display: none !important;
}

.st-spelling-suggestion-link a {
  color: var(--color-ocean) !important;
  font-family: var(--font-family-base) !important;
}

// Autocomplete

.st-ui-autocomplete,
.st-ui-slide-autocomplete {
  border: none !important;
  border-radius: 0 0 5px 5px !important;
  box-shadow: var(--global-box-shadow) !important;
  font-family: var(--font-family-base) !important;
  margin-top: 1px !important;
  position: fixed;
  top: calc(var(--global-header-height) + 60px);
  width: calc(100% - 5rem);

  @include media($full-navigation) {
    top: calc(var(--global-header-height) - 2px) !important;
    width: calc(var(--global-search-width) - 33px) !important;
  }
}

.st-query-present {
  box-shadow: none !important;
  font-family: var(--font-family-base) !important;
  font-size: 14px !important;
  margin-top: 0 !important;
}

.st-ui-type-heading,
.st-ui-type-heading em {
  font-family: var(--font-family-headings-bold) !important;
  font-size: 1rem !important;
  font-weight: var(--font-weight-medium) !important;
  line-height: 1.4 !important;
}

.st-ui-type-detail,
.st-ui-type-detail-bold {
  color: var(--color-anvil) !important;
  font-size: 16px !important;
  max-height: 44px !important;

  em {
    font-family: var(--font-family-base) !important;
  }
}

// ---------------------------------------------------------

.cc-window {
  transition: opacity 300ms ease !important;
}

// ---------------------------------------------------------

.sentry-error-embed {
  background-color: var(--color-white) !important;
  border-radius: var(--global-border-radius) !important;
  padding: 2rem 1.5rem !important;
}

.sentry-error-embed h2 {
  color: black !important;
  font-family: var(--font-family-headings) !important;
  font-size: 2em !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: 1.2 !important;
}

.sentry-error-embed header p,
.sentry-error-embed header p span {
  cursor: text !important;
  font-family: var(--font-family-base) !important;
  font-size: var(--font-size-base) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: 1.5 !important;
  margin-bottom: 1.25rem !important;
  margin-top: 0 !important;
}

.sentry-error-embed .form-field label,
.sentry-error-embed .form-submit .close {
  color: var(--color-slate) !important;
  float: left;
  font-family: var(--font-family-headings-bold) !important;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.0938rem !important;
  line-height: 1.2em;
  margin-top: 0.125rem;
  padding-top: 0.3em;
  text-transform: uppercase;
  width: fit-content !important;
}

.sentry-error-embed .form-field input,
.sentry-error-embed .form-field textarea {
  border: 0.0625rem solid var(--color-prime-gray) !important;
  border-radius: 0.3125rem !important;
  color: var(--color-off-black) !important;
  margin-top: 0.375rem !important;
  padding: 0.5rem 0.625rem 0.625rem !important;
  width: 100% !important;
}

.sentry-error-embed .form-submit .btn {
  background: var(--color-off-black) !important;
  border: none !important;
  border-radius: var(--global-button-border-radius) !important;
  color: var(--color-white) !important;
  font-family: var(--font-family-headings-bold) !important;
  font-size: 16px !important;
  line-height: 26px !important;
  padding: 0.5rem 1.5625rem !important;
  transition: background-color 250ms ease, color 250ms ease !important;

  @include hover {
    background: var(--color-moss) !important;
    color: var(--color-off-black) !important;
  }
}

// --------------------------------------------------------- Carousel component

.swiper-container {
  padding-bottom: 14px !important;
}

.swiper-pagination-bullets {
  bottom: -2px !important;
}

// --------------------------------------------------------- Styling for the Image Comparison component

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(233 249 65 / 100%);
  }

  100% {
    box-shadow: 0 0 6px 10px rgb(233 249 65 / 0%);
  }
}

.__rcs-handle-root {
  .__rcs-handle-button {
    animation: pulse 1500ms infinite;
    height: 40px !important;
    width: 40px !important;

    > div {
      border-bottom: 5px solid transparent !important;
      border-right: 8px solid !important;
      border-top: 5px solid transparent !important;
    }
  }

  .__rcs-handle-line {
    &::before {
      background-color: var(--color-white);
      border-radius: 7.5px;
      bottom: 0;
      content: '';
      display: inline-block;
      height: 13px;
      position: absolute;
      transform: translateX(-6.5px);
      width: 13px;
    }
  }

  .__rcs-handle-line:first-of-type {
    &::before {
      background-color: var(--color-white);
      border-radius: 7.5px;
      content: '';
      display: inline-block;
      height: 13px;
      position: static;
      transform: translateX(-5.5px) translateY(-2.5px);
      width: 13px;
    }
  }
}
