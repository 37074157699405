@font-face {
  font-display: swap;
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: normal;

  /* prettier-ignore */
  src: local('MarkWXX-Regular'),
    url('./mark-pro/subset-MarkWXX-Regular.woff2') format('woff2'),
    url('./mark-pro/subset-MarkWXX-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Mark Pro Medium';
  font-style: normal;
  font-weight: normal;

  /* prettier-ignore */
  src: local('MarkWXX-Medium'),
    url('./mark-pro/subset-MarkWXX-Medium.woff2') format('woff2'),
    url('./mark-pro/subset-MarkWXX-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Mark Pro Italic';
  font-style: italic;
  font-weight: normal;

  /* prettier-ignore */
  src: local('MarkWXX-Italic'),
    url('./mark-pro/subset-MarkWXX-Italic.woff2') format('woff2'),
    url('./mark-pro/subset-MarkWXX-Italic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Mark Pro Medium';
  font-style: normal;
  font-weight: normal;

  /* prettier-ignore */
  src: local('MarkWXX-Medium'),
    url('./mark-pro/subset-MarkWXX-Medium.woff2') format('woff2'),
    url('./mark-pro/subset-MarkWXX-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Mark Pro Bold';
  font-style: normal;
  font-weight: bold;

  /* prettier-ignore */
  src: local('MarkWXX-Bold'),
    url('./mark-pro/subset-MarkWXX-Bold.woff2') format('woff2'),
    url('./mark-pro/subset-MarkWXX-Bold.woff') format('woff');
}

// ---------------------------------------------------------

@font-face {
  font-display: swap;
  font-family: Untitled;
  font-style: normal;
  font-weight: normal;

  /* prettier-ignore */
  src: local('UntitledSerif-Regular'),
    url('./untitled/subset-UntitledSerif-Regular.woff2') format('woff2'),
    url('./untitled/subset-UntitledSerif-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Untitled Bold';
  font-style: normal;
  font-weight: bold;

  /* prettier-ignore */
  src: local('UntitledSerif-Bold'),
    url('./untitled/subset-UntitledSerif-Bold.woff2') format('woff2'),
    url('./untitled/subset-UntitledSerif-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Untitled Italic';
  font-style: normal;
  font-weight: bold;

  /* prettier-ignore */
  src: local('UntitledSerif-Italic'),
    url('./untitled/untitled-serif-web-regular-italic.woff2') format('woff2'),
    url('./untitled/untitled-serif-web-regular-italic.woff') format('woff');
}
