@use 'global' as *;
// ---------------------------------------------------------
// (S)CSS Modules are being used to scope CSS, so consider using mixins before creating global utility classes.
// ---------------------------------------------------------

@forward './global/utilities/';

// --------------------------------------------------------

.container {
  @include container;
}
