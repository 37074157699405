// --------------------------------------------------------
// global variables should start with --global
// ---------------------------------------------------

:root {
  --global-border-radius: 10px;
  --global-button-border-radius: 20px;
  --global-box-shadow: 0 5px 15px 2px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  --global-box-shadow-hover: 0 5px 15px 2px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  --global-header-height: 64px;
  --global-search-width: 400px;
  --global-max-width: 1440px;
  --global-title-width: 1080px;
  --global-padding: 20px;
  --global-text-color: var(--color-off-black);
  --global-grid-gutter: 30px;
}
