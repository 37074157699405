// z-index variables -- All variables should start with z-index
// ---------------------------------------------------

:root {
  --z-index-minus-one: -1;
  --z-index: 0;
  --z-index-plus-one: 1;
  --z-index-debug: 10;
  --z-index-plus-two: 2;
  --z-index-plus-three: 3;
  --z-index-plus-four: 4;
}
