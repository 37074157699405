@use 'media' as *;

// --------------------------------------------------------

@mixin container {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--global-max-width);
  padding-left: var(--global-padding);
  padding-right: var(--global-padding);
}

// --------------------------------------------------------

@mixin caption {
  background-color: var(--color-cloud);

  span {
    display: block;
    font-family: var(--font-family-base-italic);
    font-size: 15px;
    line-height: 1.4;
    padding: 11px 20px 12px;
    width: 100%;
  }
}

// --------------------------------------------------------

@mixin two-card-grid {
  @include container;

  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  margin-bottom: 40px;

  > * {
    @include media($small) {
      flex-basis: calc(100% / 2);
      max-width: calc(100% / 2 - 20px);
    }

    > * {
      height: 100%;
    }
  }
}

@mixin three-card-grid {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 40px;

  @include container;

  @include media($medium) {
    gap: 30px;
  }

  > * {
    @include media($small, $medium) {
      flex-basis: calc(100% / 2);
      max-width: calc(100% / 2 - 10px);
    }

    @include media($medium) {
      max-width: calc(100% / 3 - 20px);
    }

    > * {
      height: 100%;
    }
  }
}
